(function (noviNs) {
    var vm = new Vue({
        el: "#login-form",
        data: {
            email: noviNs.modelEmail,
            password: '',
            errorMessage: noviNs.modelErrorMessage,
            showEmailForm: !noviNs.modelEmail || noviNs.modelEmail.length == 0,
            showPasswordForm: noviNs.modelEmail.length > 0,
            showEmailNotFound: false,
            showMemberFoundWithNoAccount: false,
            showPasswordNotSet: false,
            ssoProviders: [],
            showPassword: false,
            showNoviAdminLogin: false,
            showNonMatchingUserAccount: false,
            isLoading: false,
            isSubmitting: false,
            isAccountCreationDisabled: noviNs.isAccountCreationDisabled
        },
        computed: {
            passwordInputType: function () {
                return this.showPassword ? "text" : "password";
            },
            toggleShowPasswordTitle: function () {
                if (this.showPassword)
                    return 'Hide Password';
                return 'Show Password';
            }
        },
        methods: {
            continueClick: function () {
                this.checkEmail();
            },
            switchToPasswordForm: function () {
                this.showEmailForm = false;
                this.showPasswordForm = true;
            },
            backToEmailClick: function () {
                this.errorMessage = "";
                this.showEmailForm = true;
                this.showPasswordForm = false;
            },
            toggleShowPassword: function () {
                this.showPassword = !this.showPassword;
            },
            submitForm: function (e) {
                if (!this.password.length) {
                    this.errorMessage = "Password is required.";
                    e.preventDefault();
                }
                else {
                    this.errorMessage = "";
                    this.isSubmitting = true;
                }
            },
            checkEmail: function () {
                if (!this.email || this.email.length == 0) {
                    this.errorMessage = "Please enter your email address."
                    return;
                }

                if (!noviNs.emailRegex.test(this.email)) {
                    this.errorMessage = "Please enter a valid email address."
                    return;
                }

                this.errorMessage = "";
                this.isLoading = true;

                $.ajax({
                    url: noviNs.duplicateEmailUrl + "?email=" + encodeURIComponent(this.email),
                    type: 'GET',
                    success: function (data) {
                        vm.isLoading = false;

                        vm.showEmailNotFound = false;
                        vm.showPasswordNotSet = false;
                        vm.showNonMatchingUserAccount = false;
                        vm.showMemberFoundWithNoAccount = !data.hasOwnProperty('userId') && data.hasDuplicate;

                        if (!data.customerActive) {
                            vm.showEmailNotFound = true;
                        }
                        else if (data.hasDuplicate && data.userId && data.hasSetPassword && !data.hasNonMatchingUserAccount) {
                            vm.switchToPasswordForm();
                        }
                        else if (data.userId && !data.hasSetPassword) {
                            vm.showPasswordNotSet = true;
                            vm.ssoProviders = data.ssoProviders || [];
                        }
                        else if (data.hasNonMatchingUserAccount) {
                            vm.showNonMatchingUserAccount = true;
                        }
                        else {
                            vm.showEmailNotFound = true;
                        }
                    }
                });
            }
        }
    });

    var mcIframe = document.getElementById("mc-iframe");

    function gotMessage(event) {
        if (event.origin !== noviNs.missionControlUrl || event.data != "mc-been-there") {
            return;
        }

        vm.showNoviAdminLogin = true;
    }

    window.addEventListener("message", gotMessage, false);

    //If developer tools are open, somehow the iFrame loads first so we have to tell it we are ready
    document.addEventListener("DOMContentLoaded", function (event) {
        mcIframe.contentWindow.postMessage("ready", noviNs.missionControlUrl);
    });

    $("#Email").on('keyup blur change paste input', function () {
        this.value = this.value.replace(/\s/g, '');
    });

    if (noviNs.returnUrl) {
        var createAccountHref = $("#join-modal .create-account-link").attr("href") + "&returnUrl=" + noviNs.returnUrl;
        $("#join-modal .create-account-link").attr("href", createAccountHref);
    }

})(noviNs);